.navbar {
  text-transform: none;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  background: rgba(255, 255, 255, 1) !important;
}

.navbar-brand img {
  height: 2.625rem;
}

.navbar-nav li a {
  color: #7a7a7a !important;
  padding-right: 0.05rem;
  padding-top: 1.2rem;
}

.navbar-light .navbar-nav .nav-link .navbar-brand {
  color: black;
  padding-top: 0.5rem;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: black !important;
}

.carousel-item {
  height: 60vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-inner {
  background-color: white !important;
}

.carousel-caption {
  padding: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.carousel-indicators li {
  background-color: #b33300;
}

.jumbotron {
  background-color: white !important;
  padding-top: 1;
  padding-bottom: 0;
}

.card {
  border: none;
}

.card-text {
  text-align: left;
}

.py-3 {
  background-color: whitesmoke;
  color: black;
}

video {
  width: 100%;
  height: auto;
}
#robot-card {
  background-color: slategray;
}
